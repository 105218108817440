var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"text":""}},[_c('v-card-title',{staticClass:"primary mb-9"},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" הוסף מודעה ")]),_c('v-spacer'),(_vm.selectedPostTypeId !== null)?_c('v-icon',{staticClass:"mx-3",attrs:{"color":"white"},on:{"click":_vm.goBack}},[_vm._v(" mdi-arrow-right ")]):_vm._e()],1),(_vm.isPostTypes && _vm.selectedPostTypeId === null)?_c('div',{staticClass:"d-flex flex-wrap justify-center"},_vm._l((_vm.postTypesOptions),function(postType,index){return _c('v-card',{key:index,staticClass:"mx-5 my-5 primary",style:('max-width: 80vw; background-color: #e0e0e0; width:160px; height:160px'),attrs:{"flat":"","tile":""},on:{"click":function($event){return _vm.selectPostType(postType)}}},[_c('v-card-title',[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(postType.text))])]),_c('v-card-text',{staticClass:"d-flex align-center justify-center"},[_c('v-icon',{style:({ 'font-size': '70px', 'color': 'white' })},[_vm._v("mdi-"+_vm._s(postType.icon))])],1)],1)}),1):_vm._e(),(_vm.fieldsExtended.length > 0 && _vm.selectedPostTypeId !== null)?_c('div',{attrs:{"max-width":"600px"}},[_c('v-card-text',{attrs:{"flat":"","tile":""}},[(_vm.fieldsExtended_primary.length > 0)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.fieldsExtended_primary),function(field){return _c('div',{key:field.name,staticClass:"mx-7 my-0 py-0"},[(field.showOptions && field.showOptions.info && field.showOptions.info !== '')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(field.type,{tag:"component",attrs:{"clearFormFlag":_vm.clearFormFlag,"fieldName":field.name,"fields":field.fields,"showOptions":field.showOptions},on:{"input":function($event){return _vm.setValue(field.name, $event)}}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(field.showOptions.info))])]):_c(field.type,{tag:"component",attrs:{"clearFormFlag":_vm.clearFormFlag,"fieldName":field.name,"fields":field.fields,"showOptions":field.showOptions},on:{"input":function($event){return _vm.setValue(field.name, $event)}}})],1)}),0):_vm._e(),(_vm.fieldsExtended_regular.length > 0)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.fieldsExtended_regular),function(field){return _c('div',{key:field.name,staticClass:"mx-7 my-0 py-0"},[(field.showOptions && field.showOptions.info && field.showOptions.info !== '')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(field.type,{tag:"component",attrs:{"clearFormFlag":_vm.clearFormFlag,"fieldName":field.name,"fields":field.fields,"showOptions":field.showOptions},on:{"input":function($event){return _vm.setValue(field.name, $event)}}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(field.showOptions.info))])]):_c(field.type,{tag:"component",attrs:{"clearFormFlag":_vm.clearFormFlag,"fieldName":field.name,"fields":field.fields,"showOptions":field.showOptions},on:{"input":function($event){return _vm.setValue(field.name, $event)}}})],1)}),0):_vm._e(),(_vm.fieldsExtended_regular.length > 0)?_c('v-divider',{staticClass:"my-5"}):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.fieldsExtended_secondary),function(field){return _c('div',{key:field.name,staticClass:"mx-7 my-0 py-0"},[(field.showOptions && field.showOptions.info && field.showOptions.info !== '')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(field.type,{tag:"component",attrs:{"clearFormFlag":_vm.clearFormFlag,"fieldName":field.name,"fields":field.fields,"showOptions":field.showOptions},on:{"input":function($event){return _vm.setValue(field.name, $event)}}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(field.showOptions.info))])]):_c(field.type,{tag:"component",attrs:{"clearFormFlag":_vm.clearFormFlag,"fieldName":field.name,"fields":field.fields,"showOptions":field.showOptions},on:{"input":function($event){return _vm.setValue(field.name, $event)}}})],1)}),0),(_vm.fieldsExtended_secondary.length > 0)?_c('v-divider',{staticClass:"my-5"}):_vm._e(),(_vm.fieldsExtended_price.length > 0)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.fieldsExtended_price),function(field){return _c('div',{key:field.name,staticClass:"mx-7 my-0 py-0"},[(field.showOptions && field.showOptions.info && field.showOptions.info !== '')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(field.type,{tag:"component",attrs:{"clearFormFlag":_vm.clearFormFlag,"fieldName":field.name,"fields":field.fields,"showOptions":field.showOptions},on:{"input":function($event){return _vm.setValue(field.name, $event)}}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(field.showOptions.info))])]):_c(field.type,{tag:"component",attrs:{"clearFormFlag":_vm.clearFormFlag,"fieldName":field.name,"fields":field.fields,"showOptions":field.showOptions},on:{"input":function($event){return _vm.setValue(field.name, $event)}}})],1)}),0):_vm._e(),(_vm.fieldsExtended_price.length > 0)?_c('v-divider',{staticClass:"my-5"}):_vm._e(),(_vm.fieldsExtended.length > 0)?_c('v-select',{attrs:{"items":_vm.userContactPersons,"item-text":"name","item-value":"id","label":"איש קשר","return-object":""},model:{value:(_vm.values.contactPerson),callback:function ($$v) {_vm.$set(_vm.values, "contactPerson", $$v)},expression:"values.contactPerson"}}):_vm._e()],1)],1):_vm._e(),(_vm.selectedPostTypeId !== null)?_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.addPost}},[_vm._v("הוסף מודעה")]),_c('v-btn',{on:{"click":_vm.clearForm}},[_vm._v("נקה טופס")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }